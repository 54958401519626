<template>
  <div
    id="admin-property-view"
    class="property-view pa-10"
    style="  background:#F2F4F4;height:100vh;"
  >
    <v-card style="padding:20px">
      <ExternalBrokerList />
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    ExternalBrokerList: () => import("./ExternalBrokerList.vue")
  },
  name: "ExternalBrokerHome",
  data() {
    return {};
  }
};
</script>
